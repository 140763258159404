// src\components\BuildDeck\BuildDeck.js
import React, { useState } from "react";
import { useFlashCards } from "../../context/FlashCardContext";
import "./BuildDeck.css"; // Ensure the CSS is imported

const BuildDeck = () => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [position, setPosition] = useState("");
  const { addCard, flashCards, clearDeck, removeCard } = useFlashCards();

  const handleAddCard = () => {
    const pos = position ? parseInt(position) : flashCards.length; // Adds to end if no position
    addCard({ question, answer }, pos);
    setQuestion("");
    setAnswer("");
    setPosition(""); // reset position
  };

  const saveDeck = () => {
    const deckData = JSON.stringify(flashCards);
    const blob = new Blob([deckData], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "flashcards_deck.json";
    link.href = url;
    link.click();
    URL.revokeObjectURL(url);
  };

  const handleNewDeck = () => {
    clearDeck();
  };

  return (
    <div className='text-section'>
      <h2>Build your deck</h2>
      <input
        type='text'
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
        placeholder='Question'
      />
      <input
        type='text'
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
        placeholder='Answer'
      />
      <input
        type='number'
        value={position}
        onChange={(e) => setPosition(e.target.value)}
        placeholder='Position (optional)'
        min='0'
        max={flashCards.length}
      />
      <button onClick={handleAddCard}>Add Card</button>
      <button onClick={saveDeck}>Save/Download Deck</button>
      <button onClick={handleNewDeck}>New Deck</button>
      {flashCards.length === 0 && <div>No cards in the deck.</div>}
      <ul>
        {flashCards.map((card, index) => (
          <li key={index}>
            {`${index}. ${card.question} - ${card.answer}`}
            <button onClick={() => removeCard(index)} className='remove-btn'>
              Remove
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BuildDeck;
