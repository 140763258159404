import React, { useState } from "react";
import { useFlashCards } from "../../context/FlashCardContext";
import "./FlashCard.css";

const FlashCard = () => {
  const { flashCards, currentFlashCardIndex, nextFlashCard } = useFlashCards();
  const [isFlipped, setIsFlipped] = useState(false);
  const card = flashCards[currentFlashCardIndex];

  if (flashCards.length === 0) {
    return (
      <p className='text-section'>
        No cards available. Please upload a card deck or build a new deck.
      </p>
    );
  }

  const handleClickCard = () => {
    setIsFlipped(!isFlipped);
  };

  const handleClickNext = () => {
    // Locate the element that will have the transition disabled
    const cardInner = document.querySelector(".flashcard-inner");

    if (cardInner) {
      // Temporarily disable the transition
      cardInner.style.transition = "none";

      // Reset the flip to ensure it's on the front side
      setIsFlipped(false);

      // Use a brief timeout to ensure the transition is disabled before changing the card
      setTimeout(() => {
        nextFlashCard();

        // Use another timeout to re-enable the transition after a short delay
        // This delay should be enough to switch cards without the flip animation
        setTimeout(() => {
          cardInner.style.transition = "";
        }, 10); // A very short delay to re-enable the transition
      }, 10); // Adjust if needed but should be short
    } else {
      // Fallback to the original logic if for some reason the element isn't found
      setIsFlipped(false);
      nextFlashCard();
    }
  };

  return (
    <div className='flashcard-container'>
      {" "}
      {/* Container for centering */}
      <div className='flashcard' onClick={handleClickCard}>
        <div className={`flashcard-inner ${isFlipped ? "flipped" : ""}`}>
          <div className='flashcard-front'>{card?.question}</div>
          <div className='flashcard-back'>{card?.answer}</div>
        </div>
      </div>
      <button className='next-button' onClick={handleClickNext}>
        Next
      </button>{" "}
      {/* Button with specific class */}
    </div>
  );
};

export default FlashCard;
