import React, { createContext, useState, useContext } from "react";

const FlashCardContext = createContext();

export const useFlashCards = () => useContext(FlashCardContext);

export const FlashCardProvider = ({ children }) => {
  const [flashCards, setFlashCards] = useState([]);
  const [currentFlashCardIndex, setCurrentFlashCardIndex] = useState(0);

  const addFlashCards = (newFlashCards) => {
    if (Array.isArray(newFlashCards)) {
      setFlashCards(newFlashCards);
      setCurrentFlashCardIndex(0); // Reset to first card
    } else {
      console.error("Attempted to add flashcards from a non-array source");
      // Additional error handling here as needed
    }
  };

  const nextFlashCard = () => {
    setCurrentFlashCardIndex(
      (prevIndex) => (prevIndex + 1) % flashCards.length
    );
  };

  const addCard = (card, position) => {
    // Define the addCard function inside FlashCardProvider to access setFlashCards
    setFlashCards((currentCards) => {
      let newCards = [...currentCards];
      if (position >= 0 && position <= newCards.length) {
        newCards.splice(position, 0, card);
      } else {
        newCards.push(card);
      }
      return newCards;
    });
  };

  const clearDeck = () => {
    setFlashCards([]); // This will reset the flashCards to an empty array
  };

  const removeCard = (index) => {
    setFlashCards((currentCards) => currentCards.filter((_, i) => i !== index));
  };

  // Make addCard part of the context value so it can be used in components
  const contextValue = {
    flashCards,
    currentFlashCardIndex,
    addFlashCards,
    nextFlashCard,
    addCard,
    clearDeck,
    removeCard,
  };

  return (
    <FlashCardContext.Provider value={contextValue}>
      {children}
    </FlashCardContext.Provider>
  );
};
