import React from "react";
import "./Navbar.css";
import { useFlashCards } from "../../context/FlashCardContext";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const { addFlashCards } = useFlashCards();
  const location = useLocation();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const cards = JSON.parse(e.target.result);
          if (Array.isArray(cards)) {
            addFlashCards(cards);
          } else {
            alert("The loaded file does not contain a valid deck.");
          }
        } catch (err) {
          alert(
            "Error parsing JSON. Please ensure the file is correctly formatted."
          );
        }
      };
      reader.readAsText(file);
    }
  };

  return (
    <section className='navbar-header'>
      <nav className='navbar'>
        <ul>
          <li>
            <a href='https://editmaker.net'>Home</a>{" "}
            {/* External link to "Home" */}
          </li>
          {location.pathname !== "/" && (
            <li>
              <Link to='/'>Study</Link>
            </li>
          )}
          {location.pathname !== "/build-deck" && (
            <li>
              <Link to='/build-deck'>Build Deck</Link>
            </li>
          )}
          {location.pathname !== "/info" && (
            <li>
              <Link to='/info'>Info</Link>
            </li>
          )}
          <li>
            <button
              className='upload-link-button'
              onClick={() => document.querySelector("#fileInput").click()}
              type='button'
            >
              Upload Card Deck
            </button>
            <input
              id='fileInput'
              type='file'
              onChange={handleFileChange} // Update the handler here
              style={{ display: "none" }}
            />
          </li>
        </ul>
      </nav>
    </section>
  );
};

export default Navbar;
