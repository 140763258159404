import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import FlashCard from "./components/FlashCard/FlashCard";
import BuildDeck from "./components/BuildDeck/BuildDeck"; // Make sure you have this component created
import Info from "./components/Info/Info";
import { FlashCardProvider } from "./context/FlashCardContext";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Updated import to include Routes

function App() {
  return (
    <FlashCardProvider>
      <Router>
        <div className='App'>
          <Navbar />
          <Routes>
            <Route path='/build-deck' element={<BuildDeck />} />
            <Route path='/info' element={<Info />} />
            <Route path='/' element={<FlashCard />} />
          </Routes>
        </div>
      </Router>
    </FlashCardProvider>
  );
}

export default App;
