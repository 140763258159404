import React from "react";
import "./Info.css";

const Info = () => {
  return (
    <div className='text-section'>
      <p className='bold-header'>About this website</p>
      <p>
        This is a flashcard app I built using React for personal use and to play
        with the framework. Feel free to use it too if you want.
      </p>
      <p className='bold-header'>How to get started</p>
      <p>
        To get started click "Build Deck" on the navbar and begin adding cards
        to your deck. You can save your deck after making one to keep it so you
        may use again later. After making your deck (and saving it if you want
        to keep it) click "Study" to use it.
      </p>
      <section className='warning-text'>
        <p className='bold-header'>
          You shouldn't use card decks from others you don't trust right now
        </p>
      </section>
      <p>
        Since this is an early version I cannot guarantee I am sanitizing deck
        uploads properly to prevent things like XSS attacks. So, I advise
        against using card decks made by others while I am still testing things.
      </p>
    </div>
  );
};

export default Info;
